.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio_item {
  background: var(--color-bg-variant);
  padding: 1rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio_item:hover {
  background: transparent;
  border-color: var(--color-bg-variant);
  cursor: default;
}

.portfolio_item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio_item-image > img {
  height: 300px;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
}

.portfolio_item h3 {
  margin-top: 20px;
}

.portfolio_item p{
  font-size: 12px;
  margin-bottom: 20px;
}

.portfolio_item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES)================= */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES)================= */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
